<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--구분-->
          <c-select
            codeGroupCd="SENIOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="seniorClassCd"
            label="LBL0010238"
            v-model="searchParam.seniorClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--분야-->
          <c-select
            codeGroupCd="SENIOR_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="seniorFieldCd"
            label="LBL0010239"
            v-model="searchParam.seniorFieldCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--사용여부-->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!--선임명 목록-->
    <c-table
      ref="table"
      title="선임명 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :checkClickFlag="false"
      rowKey="seniorId"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!--추가-->
            <c-btn v-if="editable" label="LBLADD" :showLoading="true" icon="add" @btnClicked="add" />
            <!--저장-->
            <c-btn
              v-if="editable && grid.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveSenior"
              @btnCallback="saveCallback" />
            <!--검색-->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <!-- <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" :showLoading="true" :editable="editable" icon="remove" @btnClicked="removeSenior" /> -->
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'educationCourseNames'">
          <c-tag 
            :editable="editable"
            icon="person_outline"
            itemText="educationCourseName"
            itemValue="check"
            name="eduList" 
            v-model="props.row.eduList"
            @addTag="addEduMaster(props.row)"
            @removeTag="item => removeEduMaster(item, props.row)"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "senior-dismissal-master",
    data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchParam: {
        seniorClassCd: null,
        seniorFieldCd: null,
        useFlag: 'Y',
      },
      editable: true,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      disabled: false,
      isSave: false,
      row: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.senior.list.url;
      this.insertUrl = transactionConfig.mdm.senior.save.url;
      this.deleteUrl = transactionConfig.mdm.senior.delete.url;
      // url setting
      // code setting
      // list setting
      this.getList();

      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('SENIOR_CLASS_CD').then(item1 => { // 선해임 구분
        // this.$comm.getComboItems('SENIOR_TYPE_CD').then(item2 => { // 분야
          this.$comm.getComboItems('SENIOR_REPORT_APPOINT_CD').then(item3 => { // 신고/선임 구분
            this.$comm.getComboItems('REGULATION_BILL_API_CD').then(item4 => { // 관련법규
              this.grid.columns = [
                {
                  required: true,
                  name: "seniorClassCd",
                  field: "seniorClassCd",
                  //구분
                  label: "LBL0010238",
                  style: 'width:100px',
                  align: "center",
                  type: 'select',
                  sortable: true,
                  comboItems: item1,
                },
                // {
                //   required: true,
                //   name: "seniorFieldCd",
                //   field: "seniorFieldCd",
                //   label: "분야",
                //   style: 'width:100px',
                //   align: "center",
                //   type: 'select',
                //   sortable: true,
                //   comboItems: item2,
                // },
                {
                  name: "relatedLaws",
                  field: "relatedLaws",
                  //관련법규
                  label: "LBL0010215",
                  style: 'width:250px',
                  align: 'center',
                  itemText: 'codeName',
                  itemValue: 'code',
                  type: "multiSelect",
                  sortable: true,
                  comboItems: item4,
                },
                {
                  required: true,
                  name: "seniorName",
                  field: "seniorName",
                  //선임명
                  label: "LBL0010216",
                  style: 'width:200px',
                  align: "left",
                  type: 'text',
                  sortable: true,
                },
                {
                  name: "educationCourseNames",
                  field: "educationCourseNames",
                  //교육과정
                  label: "LBL0010233",
                  style: 'width:250px',
                  align: 'center',
                  type: "custom",
                  sortable: true,
                },
                {
                  name: "qualifiyRequire",
                  field: "qualifiyRequire",
                  //세부법규
                  label: "LBL0010243",
                  align: "left",
                  type: 'textarea',
                  style: 'width:250px',
                  sortable: true,
                },
                // {
                //   name: "seniorStandard",
                //   field: "seniorStandard",
                //   label: "선임기준",
                //   align: "left",
                //   type: 'textarea',
                //   style: 'width:250px',
                //   sortable: true,
                // },
                {
                  name: "reportAppointCd",
                  field: "reportAppointCd",
                  //신고/선임
                  label: "LBL0010242",
                  align: "center",
                  style: 'width:100px',
                  type: 'select',
                  sortable: true,
                  comboItems: item3,
                },
                {
                  required: true,
                  name: "useFlag",
                  field: "useFlag",
                  //사용여부
                  label: "LBLUSEFLAG",
                  align: "center",
                  style: 'width:80px',
                  type: 'check',
                  sortable: true,
                  true: 'Y',
                  false: 'N'
                },
              ]
            });
          // });
        });
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        seniorId: uid(),
        relatedLaws: '',
        qualifiyRequire: '',
        useFlag: 'Y',
        seniorName: '',
        seniorStandard: '',
        seniorClassCd: null,
        reportAppointCd: null,
        seniorFieldCd: null,
        regUserId: '',
        chgUserId: '',
        eduList: [],
        editFlag: 'C',
      })
    },
    /* eslint-disable no-unused-vars */ 
    saveSenior() {
      let isConti = true;
      let checkItem = ['seniorClassCd', 'seniorName', 'useFlag' ]
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010015',  // 필수 입력값을 입력해 주세요. [구분, 선임명, 사용여부]
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeSenior() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, _item => {
              this.grid.data = this.$_.reject(this.grid.data, { seniorId: _item.seniorId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    addEduMaster(row) {
      this.row = row;
      this.popupOptions.title = 'LBL0010244'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCoursePop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let sum = 0;
      if (!this.row.eduList) this.row.eduList = [];
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, item => {
          if (this.$_.findIndex(this.row.eduList, { eduCourseId: item.eduCourseId }) === -1) {
            this.row.eduList.push({
              eduCourseId: item.eduCourseId,
              educationCourseName: item.educationCourseName,
              seniorId: this.row.seniorId,
            })
          }
          if (this.row.editFlag !== 'C') {
            this.row.editFlag = 'U'
          }
        })
      }
    },
    removeEduMaster(item, row) {
      row.eduList = this.$_.reject(row.eduList, {eduCourseId: item.eduCourseId})
      row.editFlag = 'U'
    }
  },
};
</script>
